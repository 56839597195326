import Vue from 'vue';
import Router from 'vue-router';
import beforeEnter from '@/utils';

const LoginPage = () => import('@/pages/LoginPage');
const DefaultLayout = () => import('@/layouts/DefaultLayout');
const DashboardPage = () => import('@/pages/dashboard/DashboardPage');
const UsersListPage = () => import('@/pages/user/UsersListPage');
const UserAddEditPage = () => import('@/pages/user/UserAddEditPage');
const ItemListPage = () => import('@/pages/item/ItemListPage');
const OrdersListPage = () => import('@/pages/item/OrdersListPage');
const ItemAddEditPage = () => import('@/pages/item/ItemAddEditPage');
const ItemSummaryPage = () => import('@/pages/item/ItemSummaryPage');
const SettingsPage = () => import('@/pages/settings/Settings');
const FeedBackPage = () => import('@/pages/feedback/FeedBackPage');
const BroadcastListPage = () => import('@/pages/push-notification/BroadcastListPage')
const BroadcastAddEditPage = () => import('@/pages/push-notification/AddEditBroadcastPage')

const NotFoundPage = () => import('@/pages/NotFoundPage.vue');

Vue.use(Router);

export default new Router({
  mode: 'hash',
  linkActiveClass: 'open active',
  linkExactActiveClass: 'open active',
  scrollBehavior: () => ({y: 0}),
  routes: [
    {
      path: '/',
      name: 'Home',
      redirect: '/dashboard',
      component: DefaultLayout,
      beforeEnter: beforeEnter,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          redirect: '/dashboard/list',
          beforeEnter: beforeEnter,
          component: {
            render(c) {
              return c('router-view');
            }
          },
          children: [
            {
              path: 'list',
              name: 'DashboardPage',
              component: DashboardPage
            }
          ]
        },
        {
          path: 'user',
          name: 'Users',
          redirect: '/user/list',
          beforeEnter: beforeEnter,
          component: {
            render(c) {
              return c('router-view');
            }
          },
          children: [
            {
              path: 'list',
              name: 'UsersListPage',
              component: UsersListPage
            },
            {
              path: 'add',
              name: 'UserAdd',
              component: UserAddEditPage
            },
            {
              path: 'edit/:id',
              name: 'UserEdit',
              component: UserAddEditPage
            }
          ]
        },
        {
          path: 'item',
          name: 'Items',
          redirect: '/item/list',
          beforeEnter: beforeEnter,
          component: {
            render(c) {
              return c('router-view');
            }
          },
          children: [
            {
              path: 'list',
              name: 'ItemListPage',
              component: ItemListPage
            },
            {
              path: 'add',
              name: 'ItemAdd',
              component: ItemAddEditPage
            },
            {
              path: 'edit/:id',
              name: 'ItemEdit',
              component: ItemAddEditPage
            },
            {
              path: 'summary/:id',
              name: 'ItemSummary',
              component: ItemSummaryPage
            }
          ]
        },
        {
          path: 'settings',
          name: 'Settings',
          redirect: '/settings/edit',
          beforeEnter: beforeEnter,
          component: {
            render(c) {
              return c('router-view');
            }
          },
          children: [
            {
              path: 'edit',
              name: 'SettingsPage',
              component: SettingsPage
            }
          ]
        },
        {
          path: '/feedback',
          name: 'FeedBack',
          component: FeedBackPage
        },
        {
          path: '/orders',
          name: 'OrdersListPage',
          component: OrdersListPage
        },
        {
          path: 'broadcast',
          name: 'Broadcast',
          redirect: '/broadcast/list',
          beforeEnter: beforeEnter,
          component: {
            render(c) {
              return c('router-view')
            }
          },
          children: [
            {
              path: 'list',
              name: 'BroadcastList',
              component: BroadcastListPage
            },
            {
              path: 'add',
              name: 'BroadcastAdd',
              component: BroadcastAddEditPage
            },
            {
              path: 'edit/:id',
              name: 'BroadcastEdit',
              component: BroadcastAddEditPage
            }
          ]
        },
      ]
    },
    {
      path: '/login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/404',
      name: 'NotFound',
      component: NotFoundPage
    },
    {
      path: '*',
      component: NotFoundPage
    }
  ]
});
